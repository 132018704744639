<template>
  <v-main id="View.vue">
    <router-view class="router__main px-14" />

    <dashboard-core-footer />
  </v-main>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "DashboardCoreView",

  components: {
    DashboardCoreFooter: () => import("./Footer"),
  },

  methods: {
    ...mapMutations("carrito", ["vaciarCarrito"]),
  },

  mounted() {
    this.$route.path == "/"
      ? this.vaciarCarrito()
      : console.log(this.$route.path);
    console.log(this.$route.path);
  },
};
</script>

<style>
.router__main {
  padding-bottom: 7rem;
}
</style>
